






















































import { defineComponent, computed,useRouter,useContext } from "@nuxtjs/composition-api";
import { SfImage } from "@storefront-ui/vue";
import apichoice from "~/modules/fortytwo/dynamicyield/helper/dynamicyield";

export default defineComponent({
   name: 'FortytwoBrand',
   components: {
    SfImage,
  },
   props: {
    data: {
      type: Object,
      default: {},
      required: true,
    },
  },
  setup(props) {
    const { apidycall, loadingdy, dyresultdata } = apichoice();
    const dydecisionID = computed( ()=> props.data?.decisionId  || '',);
    const router = useRouter();
    const { app } = useContext();
    const brandArray = computed(() => Array.isArray(props.data?.data) ? props.data?.data.sort((a, b) => a.order - b.order) : props.data?.data ?? []);
    const currentURL = window.location.href;

    const addEngagements =  (imagelink: string, decisionID: string, variationsId: number) => {
       apidycall(decisionID,"CLICK","Engagements", [variationsId],"",currentURL);  
    }

    return {
      dydecisionID,
      brandArray,
      addEngagements
    };
  },
})

