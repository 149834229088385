




































import {
  defineComponent,
  ref,
  useRouter,
  useContext
} from "@nuxtjs/composition-api";
import { SfImage } from "@storefront-ui/vue";
import apichoice from "~/modules/fortytwo/dynamicyield/helper/dynamicyield";
import SkeletonLoader from "~/components/SkeletonLoader/index.vue";

export default defineComponent({
  name: "FortytwoHeroBanner",
  components: {
    SfImage,
    SkeletonLoader,
  },
  props: {
    data: {
      type: Object,
      default: {},
      required: true,
    },
  },
  setup(props) {
    const { apidycall, loadingdy, dyresultdata } = apichoice();
    const imageDesktop = ref("");
    const imageTable = ref("");
    const imageSmartPhone = ref("");
    const dydata :any = ref();
    const router = useRouter();
    const { app } = useContext();
    dydata.value = props.data;
    const currentURL = window.location.href;

    const addEngagements =  (imagelink: string, decisionID: string, variationsId: number) => {
       apidycall(decisionID,"CLICK","Engagements", [variationsId],"",currentURL);  
    }

    return {
      dydata,
      imageDesktop,
      imageTable,
      imageSmartPhone,
      addEngagements
    };
  },
});
