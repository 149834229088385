





























import { defineComponent, ref, onMounted, onBeforeUnmount } from '@nuxtjs/composition-api';
import useFtGetInstagramPhoto from '~/composables/FortyTwo/useFtGetInstagramPhoto';
import useFtScrollElement from '~/composables/FortyTwo/useFtScrollElement';

export default defineComponent({
   name: 'FortytwoInstaHome',
   setup() {
    const instagramPhotos = ref([]);
    const instagramEnabled = ref(false);
    const { getInstagramPhotoInfo } = useFtGetInstagramPhoto();
    const { slider, clickAndDrag, removeClickAndDragEvent, autoScroll, removeAutoScrollEvent, clearTimeoutHandle } = useFtScrollElement();

    const fetchInstagramData = async () => {
        const { data } = await getInstagramPhotoInfo();
        instagramEnabled.value = data.getInstagramPhotoInfo.enabled;
        instagramPhotos.value = data.getInstagramPhotoInfo.instagram_photos;
    };

    onMounted(async () => {
        await fetchInstagramData();
        if (instagramEnabled.value) {
            autoScroll();
            clickAndDrag();
        }
    });

    onBeforeUnmount(()=>{
        if (instagramEnabled.value) {
            removeAutoScrollEvent();
            removeClickAndDragEvent();
            clearTimeoutHandle();
        }
    });
    
    return {
        slider,
        instagramPhotos,
        instagramEnabled,
    };
   }
})
