import { ref } from "@nuxtjs/composition-api";

export const useFtScrollElement = () => {
  // Scroll and drag div horizontal
  const timeoutIds = ref([]);
  const slider = ref<HTMLElement | null>(null);
  const scroll_speed = 1;
  const classOnEvent = 'grabbed_elem';
  let isDown = false;
  let isDragged = false;
  let startX;
  let scrollLeft;

  const preventClick = (e) => {
    e.preventDefault();
    e.stopImmediatePropagation();
  }

  const mouseDownHandle = (e) => {
    e.preventDefault();
    isDown = true;
    slider.value.classList.add(classOnEvent);
    startX = e.pageX - slider.value.offsetLeft;
    scrollLeft = slider.value.scrollLeft;
  };

  const mouseLeaveHandle = () => {
    isDown = false;
    slider.value.classList.remove(classOnEvent);
  };

  const mouseUpHandle = () => {
    isDown = false;
    slider.value.classList.remove(classOnEvent);
    if (isDragged) {
      document.body.addEventListener('click', preventClick);
    } else {
      document.body.removeEventListener('click', preventClick);
    }
    isDragged =  false;
  };

  const mouseMoveHandle = (e) => {
    if(!isDown) return;
    isDragged =  true;
    e.preventDefault();
    const x = e.pageX - slider.value.offsetLeft;
    const walk = (x - startX) * scroll_speed; //scroll-fast
    slider.value.scrollLeft = scrollLeft - walk;
  };

  const clickAndDrag = () => {
    slider.value.addEventListener('mousedown', mouseDownHandle);
    slider.value.addEventListener('mouseleave', mouseLeaveHandle);
    slider.value.addEventListener('mouseup', mouseUpHandle);
    slider.value.addEventListener('mousemove', mouseMoveHandle);
  };

  const removeClickAndDragEvent = () => {
    document.body.removeEventListener('click', preventClick);
    slider.value.removeEventListener('mousedown', mouseDownHandle);
    slider.value.removeEventListener('mouseleave', mouseLeaveHandle);
    slider.value.removeEventListener('mouseup', mouseUpHandle);
    slider.value.removeEventListener('mousemove', mouseMoveHandle);
  };

  //  Auto slide left when the div is in view point
  let startSlide = 0;

  const divScroll = () => {
    slider.value.scrollLeft = slider.value.scrollLeft + 1;
    const timeoutId = setTimeout(divScroll, 20);
    timeoutIds.value.push(timeoutId);
  };

  const isInViewport = (el) => {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  const scrollHandle = () => {
    if(isInViewport(slider.value)) {
      startSlide++
    }
    if (startSlide === 1) {
      const timeoutId = setTimeout(divScroll, 2000);
      timeoutIds.value.push(timeoutId);
    }
  };

  const autoScroll = () => {
    document.addEventListener('scroll', scrollHandle);
  };

  const removeAutoScrollEvent = () => {
    document.removeEventListener('scroll', scrollHandle);
  };
  
  const clearTimeoutHandle = () => {
    if (timeoutIds.value.length > 0) {
      timeoutIds.value.forEach((id) => clearTimeout(id));
    }
  };

  return {
    slider,
    clickAndDrag,
    removeClickAndDragEvent,
    autoScroll,
    removeAutoScrollEvent,
    clearTimeoutHandle,
  }
}

export default useFtScrollElement;