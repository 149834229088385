








import { defineComponent, ref, onMounted } from '@nuxtjs/composition-api';
import { useContent } from '~/composables';
import type { CmsBlock } from '~/modules/GraphQL/types';
import ContentBlock from '~/components/ContentBlock.vue';

export default defineComponent({
    name: 'FortytwoPromo',
    components: {
        ContentBlock,
    },
    setup() {
        const {
            loadBlocks,
        } = useContent();
        const blocks = ref<CmsBlock[]>([]);
        const isContentReady = ref(false);

        onMounted(async () => {
            blocks.value = await loadBlocks({ identifiers: ['promo-panel'] });
            isContentReady.value = blocks?.value?.[0]?.content ? true : false;
        });

        return {
            blocks,
            isContentReady,
        };
    },
});
