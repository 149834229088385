

































import {
  defineComponent,
  ref,
  onMounted,
  useFetch,
  computed,
  useContext,
} from '@nuxtjs/composition-api';
import LazyHydrate from 'vue-lazy-hydration';
import { useCache, CacheTagPrefix } from '@vue-storefront/cache';
import { SfBanner, SfBannerGrid } from '@storefront-ui/vue';
import { CmsPage } from '~/modules/GraphQL/types';
import HeroSection from '~/components/HeroSection.vue';
import { getMetaInfo } from '~/helpers/getMetaInfo';
import { useContent, useUiState } from '~/composables';
import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';
import Promo from "~/components/FortyTwo/homepage/Promo.vue";
import Happening from "~/components/FortyTwo/homepage/Happening.vue";
import HeroBanner from "~/components/FortyTwo/homepage/HeroBanner.vue";
import Highlight from "~/components/FortyTwo/homepage/Highlight.vue";
import Beautiful from "~/components/FortyTwo/homepage/Beautiful.vue";
import HappeningWithEmpty from "~/components/FortyTwo/homepage/HappeningWithEmpty.vue";
import BeautifulWithEmpty from "~/components/FortyTwo/homepage/BeautifulWithEmpty.vue";
import ShopByStyleWithEmpty from "~/components/FortyTwo/homepage/ShopByStyleWithEmpty.vue";
import DYDataHandler from "~/components/FortyTwo/homepage/DYRecommendation/DYDataHandler.vue";
import BrandWithEmpty from "~/components/FortyTwo/homepage/BrandWithEmpty.vue";
import Brand from "~/components/FortyTwo/homepage/Brand.vue";
import ShopByStyle from "~/components/FortyTwo/homepage/ShopByStyle.vue";
import InstaHome from "~/components/FortyTwo/homepage/InstaHome.vue";
import apichoice from "~/modules/fortytwo/dynamicyield/helper/dynamicyield";
import DYProductWithEmpty from "~/components/FortyTwo/homepage/DYRecommendation/DYProductWithEmpty.vue";
import SkeletonLoader from "~/components/SkeletonLoader/index.vue";
import CustomHead from '~/dy/customHead.vue';
import useFtSendFacebookPixel from '~/composables/FortyTwo/useFtSendFacebookPixel';

export default defineComponent({
  name: 'FortytwoHomePage',
  components: {
    HeroSection,
    LazyHydrate,
    LoadWhenVisible,
    SfBanner,
    SfBannerGrid,
    CallToAction: () => import(/* webpackPrefetch: true */ '~/components/CallToAction.vue'),
    InstagramFeed: () => import(/* webpackPrefetch: true */ '~/components/InstagramFeed.vue'),
    MobileStoreBanner: () => import(/* webpackPrefetch: true */ '~/components/MobileStoreBanner.vue'),
    NewProducts: () => import(/* webpackPrefetch: true */ '~/components/NewProducts.vue'),
    Promo,
    Happening,
    HeroBanner,
    Highlight,
    Beautiful,
    Brand,
    ShopByStyle,
    InstaHome,
    SkeletonLoader,
    BeautifulWithEmpty,
    HappeningWithEmpty,
    ShopByStyleWithEmpty,
    BrandWithEmpty,
    DYDataHandler,
    DYProductWithEmpty,
    CustomHead,
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    const { apidycall, loadingdy, dyresultdata } = apichoice();
    const { sendFacebookPixel } = useFtSendFacebookPixel();
    const dydata:any = ref("");
    const { addTags } = useCache();
    const { loadPage } = useContent();
    // const page = ref<CmsPage | null>(null);
    const isherobannerready = ref(false);
    const isbeautifulready = ref(false);
    const isHappeningready = ref(false);
    const heroBannerData = ref();
    const Beautifulforroom = ref();
    const HappeningNow = ref();
    const shopByStyle = ref();
    const shopByBranchs = ref();
    const DYProduct = ref();
    const selectType = ["HeroBanner","Beautifulforroom","[Home] Recommendations","Happening Now","Shop By Style","Shop By Brands"];
    

    const fetchDYData = async () => {
      const currentURL = window.location.href;
      await apidycall(selectType,"HOMEPAGE","Choose",[],[],currentURL);
      dydata.value = dyresultdata.value;
      for (let campaignIndex = 0; campaignIndex < dyresultdata.value.length; campaignIndex++) {
        if(dyresultdata.value[campaignIndex].campaigntype == "HeroBanner"){
          heroBannerData.value = { data : dyresultdata.value[campaignIndex].data, decisionId : dyresultdata.value[campaignIndex].decisionId};
        }else if(dyresultdata.value[campaignIndex].campaigntype == "Beautifulforroom"){
          Beautifulforroom.value = { data : dyresultdata.value[campaignIndex].data, decisionId : dyresultdata.value[campaignIndex].decisionId};
        }else if(dyresultdata.value[campaignIndex].campaigntype == "[Home] Recommendations"){
          DYProduct.value = { data : dyresultdata.value[campaignIndex].data, decisionId : dyresultdata.value[campaignIndex].decisionId, title:dyresultdata.value[campaignIndex].title, itemsku:dyresultdata.value[campaignIndex].itemsku, variationsId : dyresultdata.value[campaignIndex].variationsId,};
        }else if(dyresultdata.value[campaignIndex].campaigntype == "Happening Now"){
          HappeningNow.value = { data : dyresultdata.value[campaignIndex].data, decisionId : dyresultdata.value[campaignIndex].decisionId};
        }else if(dyresultdata.value[campaignIndex].campaigntype == "Shop By Style"){
          shopByStyle.value = { data : dyresultdata.value[campaignIndex].data, decisionId : dyresultdata.value[campaignIndex].decisionId};
        }else if(dyresultdata.value[campaignIndex].campaigntype == "Shop By Brands"){
          shopByBranchs.value = { data : dyresultdata.value[campaignIndex].data, decisionId : dyresultdata.value[campaignIndex].decisionId};
        }
      }
    };
    
    useFetch(async () => {
      // page.value = await loadPage({ identifier: 'home' });  
      addTags([{ prefix: CacheTagPrefix.View, value: 'home' }]);
    });

    onMounted( async () => {
      if(process.client){
        fetchDYData();
      }

      // facabook pixel and conversions api
      await sendFacebookPixel('PageView', '');
    });

    // @ts-ignore
    return {
      heroBannerData,
      Beautifulforroom,
      DYProduct,
      HappeningNow,
      shopByStyle,
      shopByBranchs,
      isDYProductready : computed(()=> DYProduct.value ? true : false),
      isShopByBrandready : computed(()=> shopByBranchs.value ? true : false),
      isShopByStyleready : computed(()=> shopByStyle.value ? true : false),
      isHappeningready : computed(()=> HappeningNow.value ? true : false),
      isbeautifulready : computed(()=> Beautifulforroom.value ? true : false),
      isherobannerready : computed(()=> heroBannerData.value ? true : false)
    };
  },
  head() {
    return getMetaInfo(null);
  },
});
