import { useApi } from '~/composables/useApi';

export const useFtGetInstagramPhoto = () => {
  const { query } = useApi();

  const getInstagramPhotoInfo:any = async () =>{
    const myGraphqlQuery = `
      query {
        getInstagramPhotoInfo{
          enabled
          instagram_photos {
            image_url
            perma_link
            user_id
          }
        }
      }
      `;  
    const { data } = await query(myGraphqlQuery);

    return {
      data,
    };
  }
  
  return {
    getInstagramPhotoInfo,
  }
}


export default useFtGetInstagramPhoto;