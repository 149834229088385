var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"banner"},[_c('div',{staticClass:"banner-title"},[_c('span',[_vm._v("We’ve Got Your Style")]),_vm._v(" "),_c('span',{staticClass:"banner-view-all"},[_c('NuxtLink',{attrs:{"to":"/shop-by-look"}},[_vm._v("See All Styles")])],1)]),_vm._v(" "),_c('div',{staticClass:"shop-by-style-row tablet-desktop-only"},_vm._l((_vm.shopByStyleArray),function(item,index){return _c('div',{key:index,staticClass:"shop-by-style-content"},[_c('a',{attrs:{"href":item.URL},on:{"click":function($event){return _vm.addEngagements(item.URL,_vm.dydecisionID,item.variationsId)}}},[(item.imageDesktop)?_c('SfImage',{staticClass:"desktop-only shop-by-style-image",attrs:{"src":item.imageDesktop,"width":380,"height":401,"alt":item.title,"placeholder":"/custom/fortytwo-loading-icon.gif","loading":"eager","image-tag":"nuxt-img","nuxt-img-config":{
            fit: 'contain',
          }}}):_vm._e(),_vm._v(" "),(item.imageTablet)?_c('SfImage',{staticClass:"tablet-only shop-by-style-image",attrs:{"src":item.imageTablet,"width":380,"height":401,"alt":item.title,"placeholder":"/custom/fortytwo-loading-icon.gif","loading":"eager","image-tag":"nuxt-img","nuxt-img-config":{
            fit: 'contain',
          }}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"shopbystyle-content-msg"},[_c('span',{staticClass:"content-title"},[_vm._v(_vm._s(item.title))]),_vm._v(" "),_c('span',{staticClass:"content-msg"},[_vm._v(_vm._s(item.message))])])],1)])}),0),_vm._v(" "),_c('Flicking',{staticClass:"smartphone-only",attrs:{"options":{ 
      align: 'prev', 
      circular: false, 
      moveType: 'freeScroll', 
      bound: true, 
      preventDefaultOnDrag: true,
      autoInit: true, 
      autoResize: true, 
      disableOnInit: false 
    }}},_vm._l((_vm.shopByStyleArray),function(item,index){return _c('div',{key:index,staticClass:"shop-by-style-content"},[_c('a',{attrs:{"href":item.URL},on:{"click":function($event){return _vm.addEngagements(item.URL,_vm.dydecisionID,item.variationsId)}}},[(item.imageSmartPhone)?_c('SfImage',{staticClass:"smartphone-only shop-by-style-image",attrs:{"src":item.imageSmartPhone,"width":268,"height":188,"alt":item.title,"placeholder":"/custom/fortytwo-loading-icon.gif","loading":"eager","image-tag":"nuxt-img","nuxt-img-config":{
            fit: 'contain',
          }}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"shopbystyle-content-msg"},[_c('span',{staticClass:"content-title"},[_vm._v(_vm._s(item.title))]),_vm._v(" "),_c('span',{staticClass:"content-msg"},[_vm._v(_vm._s(item.message))])])],1)])}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }